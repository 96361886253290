// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";

// declare global {
//   interface Date {
//     getDisplayDay(): string;
//     format(format: string): string;
//   }
// }

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

reportWebVitals();

// const isSmartPhone = () => {
//   if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
//     return true;
//   } else {
//     return false;
//   }
// };

setTimeout(() => {
  // (document as any)
  //   .querySelector("body")
  //   .classList.add(`is-${isSmartPhone() ? "sp" : "pc"}`);
}, 0);
